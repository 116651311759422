* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
}

:root {
  --headerHeight: 70px;
  --footerHeight: 70px;
  /* 44px; */
  --mainHeight: calc(100vh - var(--headerHeight) - var(--footerHeight));

  --pageWidth: 60ch;
  --unit: 0.5rem;

  --dodger-blue: #1e90ffff;
  --opal: #c2d3cdff;
  --dark-liver: #56494cff;
  --sandy-brown: #f29e4cff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
  height: 100%;
}

img {
  max-width: 100%;
  display: block;
}
